/* eslint-disable import/no-unassigned-import */
/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

// You can delete this file if you're not using it

require('typeface-source-sans-pro')
require('whatwg-fetch')

import './src/index.css'

export {wrapRootElement} from './src/apollo/wrap-root-element'

export const onClientEntry = () => {
  // Don't need to do anything here, but if you don't
  // export something, the import won't work.
}

export const shouldUpdateScroll = () => {
  const main = document.getElementsByTagName('main')[0]
  if (main) main.scrollTop = 0
}
