/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import fetch from 'isomorphic-fetch'
import {createAuthLink} from 'aws-appsync-auth-link'
import {createSubscriptionHandshakeLink} from 'aws-appsync-subscription-link'

import {ApolloLink, ApolloClient, InMemoryCache} from '@apollo/client'

import Auth from '@aws-amplify/auth'
import {Amplify} from '@aws-amplify/core'

const url = process.env.GRAPHQL_API_URL
const region = 'us-east-1'

// Amplify.Logger.LOG_LEVEL = 'DEBUG'

if (typeof window !== `undefined`) {
  Amplify.configure({
    Auth: {
      region,
      userPoolId: process.env.COGNITO_USER_POOL_ID,
      userPoolWebClientId: process.env.COGNITO_USER_POOL_WEB_CLIENT_ID,

      oauth: {
        domain: process.env.COGNITO_USER_POOL_OAUTH_DOMAIN,
        scope: ['email', 'profile', 'openid', 'aws.cognito.signin.user.admin'],
        redirectSignIn: window.location.origin,
        redirectSignOut: window.location.origin,
        responseType: 'code',
      },
    },
    ssr: true,
  })
}

const refreshToken = async () => {
  try {
    const cognitoUser = await Auth.currentAuthenticatedUser()
    const currentSession = cognitoUser.signInUserSession
    return new Promise((resolve, reject) => {
      cognitoUser.refreshSession(currentSession.refreshToken, (error, session) => {
        if (error) {
          console.error(`cognitoUser.refreshSession() error: ${String(error)}`)
          reject(error)
        } else {
          resolve(session.getIdToken().getJwtToken())
        }
      })
    })
  } catch (error) {
    console.error(`refreshToken() error: ${String(error)}`)
  }
}

const auth = {
  type: 'AMAZON_COGNITO_USER_POOLS',
  jwtToken: async () => {
    try {
      const session = await Auth.currentSession()
      return session.getIdToken().getJwtToken()
    } catch (error) {
      console.error(`Auth.currentSession() error: ${String(error)}`)
      const refreshedToken = await refreshToken()
      // eslint-disable-next-line @typescript-eslint/no-unsafe-return
      return refreshedToken
    }
  },
}

const link = ApolloLink.from([
  createAuthLink({url, region, auth}),
  createSubscriptionHandshakeLink({url, region, auth}),
])
const cache = new InMemoryCache()

export const client = new ApolloClient({
  link,
  cache,
  fetch,
})
